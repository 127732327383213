<div class="tab-content-wrapper">
  <div class="row">
    <div class="col-md-6 article-details">
      <div class="row">
        <div class="col-md-6 form-label">Language</div>
        <div class="col-md-6 form-text">{{ itemData.lang }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Title</div>
        <div class="col-md-6 form-text">{{ itemData.title }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Date from</div>
        <div class="col-md-6 form-text">{{ (itemData.dateFrom | date : "dd MMM Y")  || '-' }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Date to</div>
        <div class="col-md-6 form-text">{{ (itemData.dateTo | date : "dd MMM Y")  || '-' }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Segments</div>
        <div class="col-md-6 form-text">
          <span *ngFor="let segment of itemData.segments">
            {{ segment.name }}
            <br/>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Cover image</div>
        <div class="col-md-6 form-text">
           <span *ngIf="image; else noImage">
            <div class="url"><a href="{{ image.href }}" target="_blank">Click here to view</a></div>
          </span>
          <ng-template #noImage>
            <span class="red">No image uploaded</span>
          </ng-template>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Published</div>
        <div class="col-md-6 form-text">{{ itemData.published ? 'Yes' : 'No' }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Created</div>
        <div class="col-md-6 form-text">{{ itemData.createdAt | date : "dd MMM yyyy hh:mm" }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Updated</div>
        <div class="col-md-6 form-text">{{ itemData.updatedAt | date : "dd MMM yyyy hh:mm" }}</div>
      </div>
    </div>
  </div>
</div>
