<div class="tab-content-wrapper">
  <div class="row">
    <div class="col-md-6 article-details">
      <div class="row">
        <div class="col-md-6 form-label">Title</div>
        <div class="col-md-6 form-text">{{ articleData.title }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Description</div>
        <div class="col-md-6 form-text">{{ articleData.description || '-' }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">SAP code</div>
        <div class="col-md-6 form-text">{{ articleData.sapCode }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Packaging</div>
        <div class="col-md-6 form-text">{{ articleData.packaging }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Sold per</div>
        <div class="col-md-6 form-text">{{ articleData.per }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Unit L/KG/PC</div>
        <div class="col-md-6 form-text">{{ articleData.lkg }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Gross price</div>
        <div class="col-md-6 form-text">{{ articleData.grossPrice }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Suggested retail price</div>
        <div class="col-md-6 form-text">{{ articleData.suggestedRetailPrice || '-' }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Quantity</div>
        <div class="col-md-6 form-text">{{ articleData.quantity }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Package content</div>
        <div class="col-md-6 form-text">{{ articleData.content }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Minimum order quantity</div>
        <div class="col-md-6 form-text">{{ articleData.minimumOrderQuantity }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">SAP activated</div>
        <div class="col-md-6 form-text">{{ articleData.activateForSap ? 'Yes' : 'No' }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Total24 activated</div>
        <div class="col-md-6 form-text">{{ articleData.activateForTotal24 ? 'Yes' : 'No' }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">SUMIS activated</div>
        <div class="col-md-6 form-text">{{ articleData.activateForSumis ? 'Yes' : 'No' }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Third party</div>
        <div class="col-md-6 form-text">{{ articleData.thirdParty ? 'Yes' : 'No' }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Order split</div>
        <div class="col-md-6 form-text">{{ articleData.orderSplit ? 'Yes' : 'No' }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Send email</div>
        <div class="col-md-6 form-text">{{ articleData.sendEmail ? 'Yes' : 'No' }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Convert to M3</div>
        <div class="col-md-6 form-text">{{ articleData.convertM3 ? 'Yes' : 'No' }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Active</div>
        <div class="col-md-6 form-text">{{ articleData.activeProduct ? 'Yes' : 'No' }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Created</div>
        <div class="col-md-6 form-text">{{ articleData.createdAt | date : "dd MMM yyyy hh:mm" }}</div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Updated</div>
        <div class="col-md-6 form-text">{{ articleData.updatedAt | date : "dd MMM yyyy hh:mm" }}</div>
      </div>
      <div class="row" *ngIf="equivalent">
        <div class="col-md-6 form-label">Equivalent article</div>
        <div class="col-md-6 form-text">
          <a routerLink="/articles/{{ equivalent.id }}">
            {{ equivalent.title }}
            ({{ equivalent.sapCode }})
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-label">Associated product</div>
        <div class="col-md-6 form-text">
          <a routerLink="/products/{{ articleData.productId }}">
            {{ articleData.productTitle }} ({{ articleData.productCode }})
          </a>
        </div>
      </div>
    </div>
    <div class="col mt-2">
      <div class="image-preview d-flex justify-content-center align-items-center">
        <img [src]="packShot.href" *ngIf="packShot"/>
        <span *ngIf="!packShot" class="no-packshot">No pack shot available</span>
      </div>
    </div>
  </div>
</div>
