import ApigilityResult from '../shared/model/apigility-result.model';
import { SegmentOption } from '../organization/organization.model';

export interface NewsCollectionResponse extends ApigilityResult {
  _embedded: {
    news: NewsData[],
  };
}

export interface NewsData {
  id: string;
  title: string;
  description: string;
  content: string;
  dateFrom: string | null;
  dateTo: string | null;
  segments: SegmentOption[];
  sendNotification: boolean;
  notificationSent: boolean;
  createdAt: string;
  updatedAt: string;
  published: boolean;
  lang: string;
  _links: {
    self: {
      href: string;
    },
    headerImg?: {
      imageAlt: string | null;
      href: string;
      id: string;
    },
    summaryImg?: {
      imageAlt: string | null;
      href: string;
      id: string;
    }
  }
}

export interface CreateNewsData {
  title: string;
  description: string;
  content: string;
  segments: string[];
  dateFrom: string | null; // YYYY-MM-DD
  dateTo: string | null; // YYYY-MM-DD
  sendNotification: boolean;
  published: boolean;
  lang: string;
}

export interface UpdateNewsData extends CreateNewsData {}

export interface DataSavedEvent {
  itemId: string
}

export class ImageType {
  public static readonly HEADER = 'header'
  public static readonly SUMMARY = 'summary'
}
