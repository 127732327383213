<a routerLink="/">Home</a> / Telemetry tank tagging
<br/><br/>
<div class="row page-header">
  <div class="col title">
    <h1>Telemetry tank tagging</h1>
  </div>
</div>
<div class="row" >
  <div class="col card-wrapper">
    <mat-card>
      <mat-card-header>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="form-wrapper">
            <form [formGroup]="form">
              <mat-form-field appearance="fill">
                <mat-label>Tanks ids (Example: 423432,5435,E45RT7)</mat-label>
                <input type="text" matInput formControlName="tankIds">
                <mat-error *ngIf="hasError('tankIds', 'required')">Tank ids is required</mat-error>
              </mat-form-field>
              </form>
          </div>
        </div>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button
          mat-flat-button color="primary"
          class="button-save"
          (click)="onSubmit()"
        >
          <div class="d-flex">
            Upload
            <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
          </div>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
