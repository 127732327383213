import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BlockedDate } from '../../blocked-delivery-dates/blocked-delivery-dates.model';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../shared/service/notification.service';
import { finalize } from 'rxjs/operators';
import { FormHelper } from '../../shared/mixin/form-helper';
import { DeliveryNotificationService } from '../delivery-notification.services';
import { DeliveryNotification } from '../delivery-notification.model';

@Component({
  selector: 'app-update-notification-form',
  templateUrl: './update-notification-form.component.html',
  styleUrls: ['./update-notification-form.component.scss']
})
export class UpdateNotificationFormComponent extends FormHelper() implements OnInit {

  public form!: UntypedFormGroup;
  public blockedDates: BlockedDate[];
  public isLoading: boolean = true;
  public isSaving: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private deliveryNotificationService: DeliveryNotificationService,
    private notificationService: NotificationService
  ) {
    super();
    this.blockedDates = [];
  }

  ngOnInit(): void {
    this.form = this.createForm();

    this.loadData();
  }

  public onSubmit() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.isSaving = true;

    this.deliveryNotificationService
      .upsert(this.form.value)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        }),
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('delivery_notification.updated');
          this.loadData();
        }
      });
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({
      title: this.fb.control('', Validators.required),
      description: this.fb.control('', Validators.required),
      enabled: this.fb.control(false)
    });
  }

  private updateForm(data: DeliveryNotification | null): void {
    if (data === null) {
      return;
    }

    this.form.patchValue({
      title: data.title,
      description: data.description,
      enabled: data.enabled
    });
  }

  private loadData() {
    this.deliveryNotificationService
      .find()
      .pipe(
        finalize( () => this.isLoading = false),
      )
      .subscribe((response) => {
        this.updateForm(response);
      });
  }
}
