import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UploadFormComponent } from './upload-form/upload-form.component';
import { TagTanksFormComponent } from "./tag-tanks-form/tag-tanks-form.component";

const routes: Routes = [
  {
    path: 'telemetry-settings-import',
    component: UploadFormComponent
  },
  {
    path: 'telemetry-tank-tagging',
    component: TagTanksFormComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TelemetrySettingsImportRoutingModule { }
