import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, startWith, Subscription } from 'rxjs';
import { AccountData, DataSavedEvent } from '../../account.model';
import { AccountService } from '../../account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize, map } from 'rxjs/operators';
import { NotificationService } from '../../../shared/service/notification.service';
import { ARTICLE_CODES } from '../../../shared/misc/regex';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { environment } from '../../../../environments/environment';
import { MatChipGrid } from '@angular/material/chips';
import { FormHelper } from '../../../shared/mixin/form-helper';

@Component({
  selector: "app-update-account-form",
  templateUrl: "./update-account-form.component.html",
  styleUrls: ["./update-account-form.component.scss"],
})
export class UpdateAccountFormComponent extends FormHelper() implements OnInit, OnDestroy {
  @Input() accountData!: AccountData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  public form!: UntypedFormGroup; // UntypedFormGroup
  public accountId!: string;

  public availableRoles: string[] = [];
  public selectedRoles: string[] = [];
  public roleOptions: Observable<string[]>;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public isLoading: boolean = true;
  public isSaving: boolean = false;

  private subscriptions: Subscription[] = [];

  @ViewChild("rolesInput") rolesInput!: ElementRef<HTMLInputElement>;
  @ViewChild("chipList") chipList!: MatChipGrid;

  @Input() roles: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder, // UntypedFormBuilder
    private accountService: AccountService,
    private notificationService: NotificationService,
  ) {
    super();

    this.roleOptions = new Observable();
    this.availableRoles = environment.tls.roleOptions;
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.accountId = this.route.snapshot.paramMap.get("id")!;

    this.subscriptions.push(
      this.form.valueChanges.subscribe((value) => {
        // Workaround: the mat-chip-list works with a text input which is only used for
        // input detection and rendering the chips. The roles are stored in an arbitrary
        // array hence the need for overwriting the role value in the form value.
        value.roles = this.selectedRoles;
      }),
    );

    this.setupRolesInputListener();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public onSelectRole(event: MatAutocompleteSelectedEvent): void {
    this.addRole(event.option.viewValue);
    this.checkRoles();
    this.rolesInput.nativeElement.value = "";
  }

  public onRemoveRole(role: string): void {
    const index = this.selectedRoles.indexOf(role);

    if (index >= 0) {
      this.selectedRoles.splice(index, 1);
    }

    this.checkRoles();
  }

  public onRoleInputLeave() {
    this.checkRoles();
  }

  public checkRoles(): boolean {
    this.chipList.errorState = this.selectedRoles.length === 0;

    return this.selectedRoles.length > 0;
  }

  private addRole(role: string): void {
    if (this.selectedRoles.includes(role)) {
      return;
    }

    this.selectedRoles.push(role);
  }

  private filterRoles(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.availableRoles.filter((role) =>
      role.toLowerCase().includes(filterValue),
    );
  }

  private setupRolesInputListener() {
    const ctrl = this.form.get("roles");
    this.roleOptions = ctrl!.valueChanges.pipe(
      startWith(null),
      map((role: string | undefined) =>
        role ? this.filterRoles(role) : this.availableRoles.slice(),
      ),
    );

    this.selectedRoles = [...this.accountData.roles];
  }

  public onSubmit(): void {
    // Roles must be checked manually because it is not a regular form control
    const formValid = this.checkRoles() && this.form.valid;

    if (!formValid) {
      return;
    }

    this.isSaving = true;
    const articleCodes = this.form.value.articleCodes ?? [];

    const payload = {
      roles: this.selectedRoles,
      articleCodes: articleCodes.length ? articleCodes.split(",") : [],
      syncProfile: this.form.value.syncProfile,
    }

    this.accountService
      .update(this.accountId, payload)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        }),
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts("account.updated");
          this.dataSaved.emit({ id: this.accountData.id });
        },
      });
  }

  private createForm(): UntypedFormGroup // UntypedFormGroup
  {
    return this.fb.group({
      roles: this.fb.control(this.accountData.roles),
      articleCodes: this.fb.control(this.accountData.articleCodes?.join(","), Validators.pattern(ARTICLE_CODES)),
      syncProfile: this.fb.control(this.accountData.syncProfile),
    });
  }
}
