import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/service/notification.service';
import { finalize } from 'rxjs/operators';
import { CreateMarketProfileData, MarketProfile } from '../market-profile.model';
import { MarketProfileService } from '../market-profile.service';
import { ARTICLE_CODES } from '../../shared/misc/regex';
import { FormHelper } from '../../shared/mixin/form-helper';

@Component({
  selector: 'app-create-market-profile-form',
  templateUrl: './create-market-profile-form.component.html',
  styleUrls: ['./create-market-profile-form.component.scss']
})
export class CreateMarketProfileFormComponent extends FormHelper() implements OnInit {

  public form!: UntypedFormGroup;
  public isSaving: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private marketProfileService: MarketProfileService,
    private notificationService: NotificationService
  ) {
    super();
    this.marketProfileData = new Observable<MarketProfile>();
  }

  ngOnInit(): void {
    this.form = this.createForm();
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.isSaving = true;

    const payload: CreateMarketProfileData = {
      name: this.form.value.name,
      articleCodes: this.form.value.articleCodes.split(','),
      isAvailableForGuest: this.form.value.isAvailableForGuest,
      isAvailableForRetail: this.form.value.isAvailableForRetail
    }

    this.marketProfileService
      .create(payload)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('market_profile.created');
          this.router.navigateByUrl('/market-profiles');
        }
      });
  }

  private createForm() {
    return this.fb.group({
      name: this.fb.control('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      articleCodes: this.fb.control(null,
        [
          Validators.required,
          Validators.pattern(ARTICLE_CODES)
        ]
      ),
      isAvailableForGuest: this.fb.control(null),
      isAvailableForRetail: this.fb.control(null)
    });
  }
}
