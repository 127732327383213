import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../shared/service/notification.service';
import { ValidationErrors } from '../telemetry-settings-import.model';
import { FormHelper } from '../../shared/mixin/form-helper';
import { finalize } from 'rxjs/operators';
import { TelemetrySettingsImportService } from "../telemetry-settings-import.service";

@Component({
  selector: 'app-upload-form',
  templateUrl: './tag-tanks-form.component.html',
  styleUrls: ['./tag-tanks-form.component.scss']
})
export class TagTanksFormComponent extends FormHelper() implements OnInit {
  private static readonly fileAttrDefault = 'Select file';

  public form!: UntypedFormGroup;

  public isSaving: boolean = false;
  public validationErrors!: ValidationErrors | null;

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(
    private fb: UntypedFormBuilder,
    private telemetrySettingsImportService: TelemetrySettingsImportService,
    private notificationService: NotificationService
  ) {
    super();
    this.validationErrors = null;
  }

  ngOnInit() {
    this.form = this.createForm();
  }

  public onSubmit() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.isSaving = true;
    this.telemetrySettingsImportService
      .tagTanks(this.form.value.tankIds)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('organization.created');
          this.router.navigateByUrl('/organizations');
        }
      });
  }

  private createForm() {
    return this.fb.group({
      tankIds: this.fb.control('', Validators.required),
    });
  }
}
