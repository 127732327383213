import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/service/notification.service';
import { Observable, switchMap } from 'rxjs';
import { filter, finalize, tap } from 'rxjs/operators';
import { FormHelper } from '../../shared/mixin/form-helper';
import { ArticleService } from '../article.services';
import { OrganizationOption } from '../../order-import/order-import.model';

@Component({
  selector: 'app-create-article-form',
  templateUrl: './create-article-form.component.html',
  styleUrls: ['./create-article-form.component.scss']
})
export class CreateArticleFormComponent extends FormHelper() implements OnInit {

  public form!: UntypedFormGroup;
  public isSaving: boolean = false;
  public productOptions: Observable<OrganizationOption[]>;
  public productOptionsMap: { [key: string]: string };
  public productOptionsLoading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private articleService: ArticleService,
    private notificationService: NotificationService
  ) {
    super();
    this.productOptions = new Observable();
    this.productOptionsMap = {};
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.setupProductInputListener();
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.isSaving = true;

    this.articleService
      .create(this.form.value)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('article.created');
          this.router.navigateByUrl('/articles');
        }
      });
  }

  public onSelectProductDisplayValue(productId: string | null): string {
    return productId && this.productOptionsMap[productId] || '';
  }

  private createForm() {
    return this.fb.group({
      title: this.fb.control('', [
        Validators.required,
        Validators.minLength(2)
      ]),
      description: this.fb.control(null),
      productId: this.fb.control('', Validators.required),
      sapCode: this.fb.control('', Validators.required),
      packaging: this.fb.control('', Validators.required),
      per: this.fb.control('', Validators.required),
      lkg: this.fb.control('', Validators.required),
      grossPrice: this.fb.control('', Validators.required),
      suggestedRetailPrice: this.fb.control(null),
      quantity: this.fb.control('', Validators.required),
      content: this.fb.control('', Validators.required),
      minimumOrderQuantity: new UntypedFormControl(0),
      total24Code: this.fb.control(null),
      activeProduct: this.fb.control(true),
      activateForSap: this.fb.control(false),
      activateForTotal24: this.fb.control(false),
      activateForSumis: this.fb.control(false),
      thirdParty: this.fb.control(false),
      orderSplit: this.fb.control(false),
      sendEmail: this.fb.control(false),
      convertM3: this.fb.control(false),
    });
  }

  private setupProductInputListener() {
    const ctrl = this.form.get('productId');

    this.productOptions = ctrl!.valueChanges
      .pipe(
        // Because we use [value] for the mat-autocomplete option, it will set the
        // input value to the mat-option [value] on selection which fires a value change.
        // This is a change we don't want to trigger a lookup for, hence the workaround
        // below which checks if the input value is found in the lookup map (which means
        // an ID was set instead of capturing user input), if yes, we cancel the operation.
        filter((value) => this.productOptionsMap[value] === undefined),
        switchMap((value: string) => {
          this.productOptionsLoading = true;

          return this.articleService
            .getProductOptions(value)
            .pipe(
              finalize(() => this.productOptionsLoading = false)
            )}),
        // Create lookup map for organization options (to make [displayWith] work properly)
        tap((values) => {
          this.productOptionsMap = {};

          values.forEach((option) => {
            this.productOptionsMap[option.id] = option.name;
          });
        })
      );
  }
}
