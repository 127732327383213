import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../shared/service/notification.service';
import { Observable, switchMap } from 'rxjs';
import { OrganizationOption, ValidationErrors } from '../order-import.model';
import { FormHelper } from '../../shared/mixin/form-helper';
import { filter, finalize, tap } from 'rxjs/operators';
import { OrderImportService } from '../order-import.service';
import { HttpStatusCode } from '@angular/common/http';
import { OrganizationService } from '../../organization/organization.service';

@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss']
})
export class UploadFormComponent extends FormHelper() implements OnInit {
  private static readonly fileAttrDefault = 'Select file';

  public form!: UntypedFormGroup;
  public fileAttr = UploadFormComponent.fileAttrDefault;
  public fileSelected!: File | null;

  public isLoading: boolean = true;
  public isSaving: boolean = false;
  public organizationOptions: Observable<OrganizationOption[]>;
  public organizationOptionsMap: { [key: string]: string };
  public organizationOptionsLoading: boolean = false;
  public validationErrors!: ValidationErrors | null;

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private orderImportService: OrderImportService,
    private organizationService: OrganizationService,
    private notificationService: NotificationService
  ) {
    super();
    this.organizationOptions = new Observable();
    this.organizationOptionsMap = {};
    this.validationErrors = null;
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.setupOrganizationInputListener();
  }

  public onSubmit() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    if (!this.fileSelected) {
      this.notificationService.error_ts('order_import.no_file_selected');

      return;
    }

    this.isSaving = true;

    this.orderImportService
      .uploadFile(this.form.value.organizationId, this.fileSelected)
      .pipe(
        finalize(() => this.isSaving = false)
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('order_import.upload_success');
          this.onReset();
        },
        error: (response) => {
          // The 422 error is used to show import validation errors.
          // Any other error will be and handled by the global error handler.
          if (response.status !== HttpStatusCode.UnprocessableEntity) {
            throw response;
          }

          this.validationErrors = response.error
        }
      });
  }

  public onSelectFile(event: Event): void {
    const files = (event.target as HTMLInputElement).files;

    if (!files) {
      return;
    }

    this.fileAttr = files[0].name;
    this.fileSelected = files[0];
  }

  public onSelectOrganizationDisplayValue(organizationId: string | null): string {
    return organizationId && this.organizationOptionsMap[organizationId] || '';
  }

  public onReset(): void {
    this.form.get('organizationId')?.reset();
    this.fileInput.nativeElement.value = '';
    this.fileSelected = null;
    this.fileAttr = UploadFormComponent.fileAttrDefault
    this.validationErrors = null;
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({
      organizationId: this.fb.control('', Validators.required),
    });
  }

  private setupOrganizationInputListener() {
    const ctrl = this.form.get('organizationId');

    this.organizationOptions = ctrl!.valueChanges
      .pipe(
        // Because we use [value] for the mat-autocomplete option, it will set the
        // input value to the mat-option [value] on selection which fires a value change.
        // This is a change we don't want to trigger a lookup for, hence the workaround
        // below which checks if the input value is found in the lookup map (which means
        // an ID was set instead of capturing user input), if yes, we cancel the operation.
        filter((value) => this.organizationOptionsMap[value] === undefined),
        switchMap((value: string) => {
          this.organizationOptionsLoading = true;

          return this.organizationService
            .getOrganizationOptions(value)
            .pipe(
              finalize(() => this.organizationOptionsLoading = false)
            )}),
        // Create lookup map for organization options (to make [displayWith] work properly)
        tap((values) => {
          this.organizationOptionsMap = {};

          values.forEach((option) => {
            this.organizationOptionsMap[option.id] = option.name + ' (' + option.code + ')';
          });
        })
      );
  }
}
