<div class="tab-content-wrapper">
  <div class="row">
    <div class="col-md-6 form-label">First name</div>
    <div class="col-md-6 form-text">{{ accountData.firstName }}</div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Last name</div>
    <div class="col-md-6 form-text">{{ accountData.lastName }}</div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Email address</div>
    <div class="col-md-6 form-text">{{ accountData.email }}</div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Phone number</div>
    <div class="col-md-6 form-text">{{ accountData.phoneNumber || "-" }}</div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Organisatie naam</div>
    <div class="col-md-6 form-text">{{ accountData.activeOrganisationName }}</div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Klantcode</div>
    <div class="col-md-6 form-text">{{ accountData.activeOrganisationCode }}</div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Profile sync enabled</div>
    <div class="col-md-6 form-text">
      {{ accountData.syncProfile ? "Yes": "No" }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Created</div>
    <div class="col-md-6 form-text">
      {{ accountData.createdAt | date : "dd MMM yyyy hh:mm" }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Last active</div>
    <div class="col-md-6 form-text">
      {{ (accountData.lastActivity | fromNow) || "-" }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 form-label">Roles</div>
    <div class="col-md-6 form-text" style="pointer-events: none">
      <mat-chip-grid *ngIf="accountData.roles.length; else noRoles">
        <mat-chip *ngFor="let role of accountData.roles">
          {{ role }}
        </mat-chip>
      </mat-chip-grid>
      <ng-template #noRoles
        ><span *ngIf="accountData.roles.length === 0">-</span>
      </ng-template>
    </div>
  </div>
</div>
