<div class="tab-content-wrapper">
  <div class="row">
    <div class="col form-wrapper">
      <form [formGroup]="form">
        <mat-button-toggle-group class="lang" formControlName="lang">
          <mat-button-toggle value="NL">NL</mat-button-toggle>
          <mat-button-toggle value="FR">FR</mat-button-toggle>
        </mat-button-toggle-group>
        <br/><br/>
        <mat-form-field appearance="fill">
          <mat-label>Title</mat-label>
          <input formControlName="title" name="title" matInput />
          <mat-error *ngIf="hasError('title', 'required')"
            >Title is required</mat-error
          >
          <mat-error *ngIf="hasError('title', 'minlength')"
            >Title must contain a minimum of 2 characters</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Description</mat-label>
          <textarea
            name="description"
            placeholder="Description here.."
            formControlName="description"
            rows="3"
            matInput
          >
          </textarea>
          <mat-error *ngIf="hasError('description', 'required')"
            >Description is required</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Link</mat-label>
          <input formControlName="link" name="link" matInput appEmptyToNull/>
          <mat-error *ngIf="hasError('link', 'pattern')">Invalid Url</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label><i>From</i> date</mat-label>
          <input
            matInput
            [matDatepicker]="datePicker1"
            [readonly]="true"
            formControlName="dateFrom"
            appEmptyToNull
          />
          <mat-datepicker-toggle matSuffix [for]="datePicker1">
          </mat-datepicker-toggle>
          <mat-datepicker #datePicker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label><i>To</i> date</mat-label>
          <input
            matInput
            [matDatepicker]="datePicker2"
            [readonly]="true"
            formControlName="dateTo"
            appEmptyToNull
          />
          <mat-datepicker-toggle matSuffix [for]="datePicker2">
          </mat-datepicker-toggle>
          <mat-datepicker #datePicker2></mat-datepicker>
        </mat-form-field>
        <mat-label>Segments</mat-label>
        <mat-form-field appearance="fill">
          <mat-chip-grid #segmentsChipList>
            <mat-chip
              *ngFor="let item of segmentsSelected | keyvalue"
              (removed)="onRemoveSegment(item.key)"
            >
              {{ item.value }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input
              #segmentsInput
              placeholder="Type to search a segment"
              matInput
              formControlName="segments"
              [matAutocomplete]="auto"
              [matChipInputFor]="segmentsChipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (focusout)="onSegmentInputLeave()"
            />
          </mat-chip-grid>
          <mat-error>At least 1 segment is required.</mat-error>
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="onSelectSegment($event)"
          >
            <mat-option
              *ngFor="let option of segmentOptions | async"
              [value]="option"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-icon *ngIf="segmentOptionsLoading" class="input-loading-spinner">
            <mat-spinner diameter="20"> </mat-spinner>
          </mat-icon>
        </mat-form-field>
        <br /><br />
        <mat-slide-toggle formControlName="published" name="published">
          Published
        </mat-slide-toggle>
        <br /><br />
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-divider class="pb-2"></mat-divider>
      <button
        mat-flat-button
        color="primary"
        class="button-save"
        (click)="onSubmit()"
      >
        <div class="d-flex">
          Save
          <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
        </div>
      </button>
    </div>
  </div>
</div>
