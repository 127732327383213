<div class="tab-content-wrapper">
  <div class="row">
    <div class="col form-wrapper">
      <form [formGroup]="form">
        <mat-form-field appearance="fill">
          <mat-label>Roles *</mat-label>
          <mat-chip-grid #chipList>
            <mat-chip
              *ngFor="let role of selectedRoles"
              (removed)="onRemoveRole(role)"
            >
              {{ role }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input
              #rolesInput
              placeholder="Type a name .."
              matInput
              formControlName="roles"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (focusout)="onRoleInputLeave()"
            />
          </mat-chip-grid>
          <mat-error *ngIf="chipList.errorState">At least 1 role is required.</mat-error>
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="onSelectRole($event)"
          >
            <mat-option *ngFor="let role of roleOptions | async">
              {{ role }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Article codes</mat-label>
          <textarea
            formControlName="articleCodes"
            name="articleCodes"
            placeholder="Insert article codes here .."
            rows="7"
            matInput
          >
        </textarea>
          <mat-error *ngIf="hasError('articleCodes', 'pattern')">Invalid format. Allowed: alpha-numeric characters [A-Z0-9] separated by a comma</mat-error>
        </mat-form-field>
        <br/><br/>
        <mat-slide-toggle
          formControlName="syncProfile"
          name="syncProfile"
        >
          Sync profile
        </mat-slide-toggle>
        <br/>
        <p class="sync-profile-explanation">This will synchronize user profile details from Cognito on first next login</p>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-divider class="pb-2"></mat-divider>
      <button
        mat-flat-button color="primary"
        class="button-save"
        (click)="onSubmit()"
      >
        <div class="d-flex">
          Save
          <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
        </div>
      </button>
    </div>
  </div>
</div>
