export const environment = {
  production: true,
  apiUrl: 'https://u2m.api.proxilubpreprod.alzp.tgscloud.net',
  apiPublicUrl: 'https://public.proxilubpreprod.alzp.tgscloud.net',
  oauth: {
    idp: 'AadLiftPP',
    clientId: '3ura9atcio42uao85kho35983v',
    issuer: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_OMvTre75X',
    redirectUri: 'https://admin.proxilubpreprod.alzp.tgscloud.net',
    postLogoutUrl: 'https://admin.proxilubpreprod.alzp.tgscloud.net',
    waitForTokenMsec: 1000,
    scope: 'openid profile email',
    requireHttps: false,
    showDebugInformation: false,
    strictDiscoveryDocumentValidation: false,
    // Setting below will automatically add token to headers
    // (make sure to set the proper URL for the API backend)
    resourceServer: {
      allowedUrls: [
        'https://u2m.api.proxilubpreprod.alzp.tgscloud.net',
        'https://public.proxilubpreprod.alzp.tgscloud.net',
      ],
      sendAccessToken: true,
    },
  },
  authorization: {
    requiredRoles: ['shop_admin'],
  },
  errorHandling: {
    httpIgnoreKeys: ['trace', 'details', 'exception_stack'],
  },
  translation: {
    defaultLanguage: 'en',
    fallbackLanguage: 'en',
  },
  tls: {
    roleOptions: ['shop_user', 'shop_admin', 'shop_salesrep'],
    organization: {
      countryOptions: [
        { id: 'NL', name: 'Netherlands' },
        { id: 'BE', name: 'Belgium' },
        { id: 'FR', name: 'France' },
      ],
    },
    telemetry: {
      oilTypes: [
        { id: 0, name: 'Fresh oil' },
        { id: 1, name: 'Waste oil' },
        { id: 2, name: 'Diesel' },
      ],
      tankTypes: [
        { id: 0, name: 'Drum' },
        { id: 1, name: 'Bulk' },
      ],
    },
  },
};
