import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ValidationErrors } from '../../order-import/order-import.model';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../shared/service/notification.service';
import { finalize } from 'rxjs/operators';
import { HttpStatusCode } from '@angular/common/http';
import { FormHelper } from '../../shared/mixin/form-helper';
import { EquivalentUploadService } from '../equivalent.service';

@Component({
  selector: 'app-import-equivalent-form',
  templateUrl: './import-equivalent-form.component.html',
  styleUrls: ['./import-equivalent-form.component.scss']
})
export class ImportEquivalentFormComponent extends FormHelper() implements OnInit {

  private static readonly fileAttrDefault = 'Select file';

  public form!: UntypedFormGroup;
  public fileAttr = ImportEquivalentFormComponent.fileAttrDefault;
  public fileSelected!: File | null;

  public isLoading: boolean = true;
  public isSaving: boolean = false;
  public validationErrors!: ValidationErrors | null;

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private equivalentUploadService: EquivalentUploadService,
    private notificationService: NotificationService
  ) {
    super();
    this.organizationOptions = new Observable();
    this.organizationOptionsMap = {};
    this.validationErrors = null;
  }

  ngOnInit(): void {
    this.form = this.createForm();
  }

  public onSubmit() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    if (!this.fileSelected) {
      this.notificationService.error_ts('order_import.no_file_selected');

      return;
    }

    this.isSaving = true;

    this.equivalentUploadService
      .uploadFile(this.form.value.type, this.fileSelected)
      .pipe(
        finalize(() => this.isSaving = false)
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('equivalent.import.upload_success');
          this.onReset();
        },
        error: (response) => {
          // The 422 error is used to show import validation errors.
          // Any other error will be and handled by the global error handler.
          if (response.status !== HttpStatusCode.UnprocessableEntity) {
            throw response;
          }

          this.validationErrors = response.error
        }
      });
  }

  public onSelectFile(event: Event): void {
    const files = (event.target as HTMLInputElement).files;

    if (!files) {
      return;
    }

    this.fileAttr = files[0].name;
    this.fileSelected = files[0];
  }

  public onSelectOrganizationDisplayValue(organizationId: string | null): string {
    return organizationId && this.organizationOptionsMap[organizationId] || '';
  }

  public onReset(): void {
    this.form.get('organizationId')?.reset();
    this.fileInput.nativeElement.value = '';
    this.fileSelected = null;
    this.fileAttr = ImportEquivalentFormComponent.fileAttrDefault
    this.validationErrors = null;
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({
      type: this.fb.control('', Validators.required),
    });
  }
}
