import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipGrid } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, switchMap } from 'rxjs';
import { filter, finalize, tap } from 'rxjs/operators';
import { SegmentOption } from '../../organization/organization.model';
import { OrganizationService } from '../../organization/organization.service';
import { FormHelper } from '../../shared/mixin/form-helper';
import { NotificationService } from '../../shared/service/notification.service';
import { MagazineData, UpdateMagazineData } from '../magazine.model';
import { MagazineService } from '../magazine.service';

@Component({
  selector: 'app-update-magazine-form',
  templateUrl: './update-magazine-form.component.html',
  styleUrls: ['./update-magazine-form.component.scss'],
})
export class UpdateMagazineFormComponent
  extends FormHelper()
  implements OnInit
{
  public form!: UntypedFormGroup;
  public isLoading: boolean = true;
  public isSaving: boolean = false;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public segmentOptions: Observable<SegmentOption[]>;
  public segmentOptionsLoading: boolean = false;
  public segmentsSelected: Map<string, string>;
  public magazineId!: string;
  public magazineData: Observable<MagazineData>;

  @ViewChild('segmentsInput') segmentsInput!: ElementRef<HTMLInputElement>;
  @ViewChild('segmentsChipList') chipList!: MatChipGrid;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private magazineService: MagazineService,
    private organizationService: OrganizationService,
    private notificationService: NotificationService
  ) {
    super();
    this.magazineData = new Observable();
    this.segmentOptions = new Observable();
    this.segmentsSelected = new Map();
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.setupSegmentInputListener();

    this.magazineId = this.route.snapshot.paramMap.get('id')!;
    this.magazineData = this.magazineService
      .find(this.magazineId)
      .pipe(
        finalize(() => this.isLoading = false),
        tap((next) => {
          this.initializeFormData(next);
        })
      );
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    const isValid = this.checkSegments() && this.form.valid;

    if (!isValid) {
      return;
    }

    this.isSaving = true;

    const payload: UpdateMagazineData = {
      title: this.form.value.title,
      shortDescription: this.form.value.shortDescription,
      description: this.form.value.description,
      url: this.form.value.url,
      segments: [...this.segmentsSelected.keys()],
    };

    this.magazineService
      .update(this.magazineId, payload)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('magazine.updated');
          this.router.navigateByUrl('/magazines');
        },
      });
  }

  public onSegmentInputLeave() {
    this.checkSegments();
  }

  public checkSegments(): boolean {
    this.chipList.errorState = this.segmentsSelected.size === 0;

    return this.segmentsSelected.size > 0;
  }

  public onSelectSegment(event: MatAutocompleteSelectedEvent): void {
    this.addSegment(event.option.value);
    this.segmentsInput.nativeElement.value = '';
    this.checkSegments();
  }

  public onRemoveSegment(key: string): void {
    if (!this.segmentsSelected.get(key)) {
      return;
    }

    this.segmentsSelected.delete(key);
    this.checkSegments();
  }

  private addSegment(option: SegmentOption): void {
    if (this.segmentsSelected.get(option.id)) {
      return;
    }

    this.segmentsSelected.set(option.id, option.name);
  }

  private setupSegmentInputListener() {
    const ctrl = this.form.get('segments');
    this.segmentOptions = ctrl!.valueChanges
      .pipe(
        filter(() => this.segmentsInput.nativeElement.value !== ''),
        switchMap((value: string | SegmentOption) => {
          // Because we use [value] for the mat-autocomplete option, it will set the input
          // value to the mat-option [value] on selection which is not desirable in our case
          // hence the type check below (if we don't receive a string, reset the options).
          const filter = typeof (value) === 'string' ? value : null;
          this.segmentOptionsLoading = filter !== null;

          return this.organizationService
            .getSegmentOptions(filter)
            .pipe(
              finalize(() => this.segmentOptionsLoading = false)
            )
        }),
      );
  }

  private createForm() {
    return this.fb.group({
      title: this.fb.control('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      shortDescription: this.fb.control('', Validators.required),
      description: this.fb.control(null),
      url: this.fb.control('', Validators.required),
      segments: this.fb.control(''), // actual value not used
    });
  }

  private initializeFormData(response: MagazineData) {
    this.form.patchValue({
      title: response.title,
      shortDescription: response.shortDescription,
      description: response.description,
      url: response.url,
    });

    response.segments.forEach((value) => {
      this.segmentsSelected.set(value.id, value.name);
    });
  }
}
