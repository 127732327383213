import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataSavedEvent, ImageType, NewsData } from '../../news.model';
import { NewsService } from '../../news.service';
import { NotificationService } from '../../../shared/service/notification.service';

@Component({
  selector: 'app-item-images',
  templateUrl: './item-images.component.html',
  styleUrls: ['./item-images.component.scss']
})
export class ItemImagesComponent {

  public fileAttrHeader = 'Select file';
  public fileAttrSummary = 'Select file';
  public showDeleteIconHeaderImage = false;
  public showDeleteIconSummaryImage = false;

  @Input() itemData!: NewsData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  constructor(
    private newsService: NewsService,
    private notificationService: NotificationService
  ) {
  }

  get headerImage() {
    return this.itemData._links?.headerImg;
  }

  get summaryImage() {
    return this.itemData._links?.summaryImg;
  }

  onSelect(event: Event, type: string): void {
    const files = (event.target as HTMLInputElement).files;

    if (!files) {
      return;
    }

    if (type === ImageType.HEADER) {
      this.fileAttrHeader = files[0].name;
    } else {
      this.fileAttrSummary = files[0].name;
    }

    this.newsService
      .uploadImage(this.itemData.id, files[0], type)
      .subscribe((next) => {
        this.dataSaved.emit({ itemId: this.itemData.id });
        this.notificationService.success_ts('news_item.image.uploaded');
      })
  }

  onRemove(type: string): void {
    this.newsService
      .uploadImage(this.itemData.id, null, type)
      .subscribe((next) => {
        this.dataSaved.emit({ itemId: this.itemData.id });
        this.notificationService.success_ts('news_item.image.removed');
      })
  }
}
